<template>

<div class="delete" :class="{'is-loading': is.loading}">

	<app-icon icon="clash" class="delete-icon" />

	<div class="delete-title">Are you sure you wish to cancel?</div>

	<div class="delete-text">

		<p>Your event <b>{{ event.name }}</b> will be permanently deleted<template v-if="event.seats.seated"> and seated users will be notified of the cancellation</template>.</p>

		<app-input-text :autogrow="true" v-model="model.reason" placeholder="Optional: Provide a reason for cancellation..." maxlength="255" class="delete-reason" />

	</div>

	<div class="delete-buttons">

		<app-button theme="red" text="Confirm" :loading="is.confirming" v-on:click="onConfirmClick" />
		<app-button theme="grey" text="Nevermind" v-on:click="onCancelClick" />

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loading: true,
				confirming: false
			},
			model: {
				reason: ''
			},
			event: {}
		}

	},

	created: function() {

		this.load();

	},

	methods: {

		onCancelClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Event'
			})

		},

		onConfirmClick: async function() {

			this.is.confirming = true

			await this.$api.patch('convention/schedule/event/' + this.$route.params.id + '/cancel', {
				description: this.model.reason
			})

			this.$api.delete('convention/schedule/event/' + this.$route.params.id + '/cancel').then(function() {

				this.$router.push({
					name: 'Convention.Schedule.Event'
				})

			}.bind(this))

		},

		load: function() {

			this.$api.get('convention/schedule/event/' + this.$route.params.id + '/cancel').then(function(response) {

				this.event = response.event

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.delete {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #c55b5b;
}

.delete.is-loading {
	pointer-events: none;
}

.delete-icon {
	font-size: 96px;
}

.delete-title {
	font-size: 24px;
	font-weight: 400;
	padding: 20px 0px;
}

.delete-text {
	font-size: 16px;
	line-height: 20px;
	padding: 0px 100px;
	text-align: center;
	color: #333;
	font-weight: 300;
}

.delete-buttons {
	margin-top: 20px;
	display: flex;
	justify-content: center;
}

.delete-buttons >>> .button {
	margin: 0px 5px;
}

.delete-reason {
	margin-top: 20px;
}

</style>
